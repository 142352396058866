import React from 'react';
import { Breadcrumb, Button, Input, Modal, Pagination, Popconfirm, Row, Switch, message, theme } from 'antd';
import { Space, Table } from 'antd';
import { NavLink, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { deleteAdvAPI, getListAdvAPI, updateDetailAdvAPI, updateStatusAdvAPI } from '../../@lib/APIs/advertisementAPI';
import { routersList } from '../../@mock/routers';
import { useState } from 'react';

export const ListAdv = () => {
    const { token: { colorBgContainer }, } = theme.useToken();
    const [dataListTop, setDataListTop] = React.useState([]);
    let navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    // const [dataTop10, setDataTop10] = useState();
    const [total, setTotal] = useState(10);
    const [pagination, setPagination] = useState({ page: 1, limit: 10 });
    let { page } = useParams();

    const columns = [
        {
            title: 'Banner / Tiêu đề',
            dataIndex: 'image_url',
            key: 'image_url',
            render: (text, record) => <>
                {(record.prior > 50 && record.prior <= 60) ? <Button style={{ background: record.code, color: "white" }}>
                    {text}
                </Button> : <img src={text} style={{ maxHeight: '100px', maxWidth: "250px", cursor: "pointer" }} alt={text} onClick={() => navigator.clipboard.writeText(text)} />}
            </>
        },
        {
            title: 'Link trang đích',
            dataIndex: 'refer',
            key: 'refer',
            render: (text) => <a href={text} target='_blank' rel="noreferrer">{text}</a>,

        },
        {
            title: 'Độ ưu tiên',
            dataIndex: 'prior',
            sorter: (a, b) => a.prior - b.prior,
            key: 'prior',
        },
        {
            title: 'Trạng thái',
            key: 'status',
            dataIndex: 'status',
            sorter: (a, b) => a.status - b.status,
            render: (_, record) => (<Switch checked={record.status == "public"} onClick={() => handleChangeStatus(record)} />),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return (
                    <Space size="middle">
                        <NavLink to={`${record.id}`}>Chi tiết </NavLink>
                        <Popconfirm
                            description="Xóa banner này?"
                            onConfirm={() => removeAdv(record.id)}
                            okText="Xóa"
                            cancelText="Hủy"
                        >
                            <span style={{ color: "red", cursor: "pointer" }}>Xóa</span>
                        </Popconfirm>
                    </Space>
                )
            },
        },
    ];

    const handleChangeStatus = async (record) => {
        try {
            setLoading(true)
            let status = "public"
            if (record.status == "public") {
                status = "hidden"
            }
            let res = await updateStatusAdvAPI(record.id, { status: status });
            if (res.status == 200) {
                setDataListTop(dataListTop.map(e => e.id === record.id ? { ...e, status: status } : e));
            }
        } catch (error) {
            alert("đã xảy ra lỗi, thử lại sau.")
        }
        setLoading(false)
    }

    const removeAdv = async (idRecord) => {
        setLoading(true)
        try {
            let res = await deleteAdvAPI(idRecord);
            if (res) {
                setDataListTop(dataListTop.filter(e => e.id != idRecord));
            }
        } catch (error) {
            console.log(error);
            errorMess()
        }
        setLoading(false)
    }

    const getList = async (page) => {
        setLoading(true)
        try {
            let res = await getListAdvAPI(page, pagination.page, pagination.limit);
            if (res) {
                let dataTable = res.data.data.filter(item => {
                    if (item.prior == 500) {
                        // setDataTop10(item);
                        return false;
                    }
                    return true;
                })
                setDataListTop(dataTable);
                setTotal(res.data.total);
            }
        } catch (error) {
            console.log(error);
            errorMess()
        }
        setLoading(false)
    }
    const errorMess = (error) => {
        messageApi.open({
            type: 'error',
            content: error ?? 'Có lỗi xảy ra, thử lại sau.',
        });
    }
    const tableProps = {
        loading
    };

    React.useEffect(() => {
        getList(page);
    }, [page, pagination]);

    return (
        <>
            {contextHolder}
            <Breadcrumb
                style={{
                    margin: '16px 0',
                }}
                items={[{ title: "Quảng cáo" }, { title: "Danh sách quảng cáo" }, { title: page.toUpperCase() },]}
            >
            </Breadcrumb>
            <div
                style={{
                    padding: 24,
                    minHeight: 360,
                    background: colorBgContainer,
                }}
            >
                <Row style={{
                    justifyContent: 'flex-end', padding: 10, gap: 15
                }}>
                    <Button type="primary" onClick={() => navigate(routersList.advCreate(page))}>Thêm quảng cáo</Button>
                </Row>
                <Table {...tableProps} columns={columns} dataSource={dataListTop} pagination={false} />
                <Pagination pageSize={pagination.limit} current={pagination.page} total={total} onChange={(page, pageSize) => {
                    setPagination({ limit: pageSize, page: page })
                }}
                    style={{ marginTop: 15 }}
                />

            </div>

        </>
    )
}
