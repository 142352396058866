import React, { useEffect } from 'react'
import { Breadcrumb, Button, Input, Popconfirm, Row, message, theme } from 'antd';
import { Space, Table, Tag } from 'antd';
import { NavLink, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { deleteDearlerAPI, getListDealersAPI } from '../../@lib/APIs/dealersAPI';
import { DealerForm } from './dialogFrom';

export const Dealers = () => {
  const { token: { colorBgContainer }, } = theme.useToken();
  const [dataListTop, setDataListTop] = React.useState([]);
  const [dataList, setDataList] = React.useState([]);
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const Search = Input.Search;
  const [messageApi, contextHolder] = message.useMessage();
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [currentData, setCurrentData] = useState(null);

  const handleClose = () => {
    setIsOpenForm(false);
    setCurrentData(null);
    getList();
  }
  const handleOpen = () => setIsOpenForm(true);
  const columns = [
    {
      title: 'Độ ưu tiên',
      dataIndex: 'prior',
      key: 'prior',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => parseInt(a.prior) - parseInt(b.prior),
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Tên đại lý',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Đánh giá',
      dataIndex: 'star',
      key: 'star',
      sorter: (a, b) => parseInt(a.star) - parseInt(b.star),
      render: (_, { star }) => (
        <Tag>
          {star}/10
        </Tag>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        return (
          <Space size="middle">
            <NavLink onClick={() => {
              setCurrentData(record);
              handleOpen()
            }}>
              Chi tiết </NavLink>
            <Popconfirm
              // title="Delete the task"
              description="Xóa trận đấu này?"
              onConfirm={() => removeDealer(record.id)}
              // onCancel={cancel}
              okText="Xóa"
              cancelText="Hủy"
            >
              <span href='#' style={{ color: "red", cursor: "pointer" }}>Xóa</span>
            </Popconfirm>
          </Space>
        )
      },
    },
  ];

  const onSearch = () => {
    if (dataListTop.length == 0) {
      setDataList([]);
      return
    };
    const reg = new RegExp(searchText, "gi");
    const filteredData = dataListTop.map(record => {
      const nameMatch = record.name && record.name.match(reg);
      if (!nameMatch) {
        return null;
      }
      return record;
    }).filter(record => record != null);
    setDataList(filteredData);
  };

  const removeDealer = async (id) => {
    setLoading(true)
    try {
      let resDel = await deleteDearlerAPI(id);
      if (resDel.status == 200) {
        let newdata = dataListTop.filter(e => e.id != id)
        setDataListTop(newdata);
      }
    } catch (error) {
      alert("đã xảy ra lỗi, thử lại sau.")
      console.log(error);
    }
    setLoading(false)
  }

  const getList = async () => {
    setLoading(true)
    try {
      let res = await getListDealersAPI();
      if (res && res.data.data && res.data.data.length > 0) {
        setDataListTop(res.data.data.map(item => item.star >= 0));
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false)
  }
  React.useEffect(() => {
    getList();
  }, []);

  React.useEffect(() => {
    onSearch();
  }, [dataListTop, searchText])

  const tableProps = {
    loading
  };

  return (
    <>
      {contextHolder}
      <Breadcrumb
        style={{
          margin: '16px 0',
        }}
        items={[{ title: "Đại lý" }, { title: "Danh sách đại lý" },]}
      >
      </Breadcrumb>
      <div
        style={{
          padding: 24,
          minHeight: 360,
          background: colorBgContainer,
        }}
      >
        <Row style={{ justifyContent: 'flex-end', padding: 10 }}>
          <Button type="primary" onClick={handleOpen}>Thêm đại lý mới</Button>

        </Row>
        <Search
          size="large"
          onChange={e =>
            setSearchText(e.target.value)}
          placeholder="Search Records"
          value={searchText}
          onPressEnter={onSearch}
          onSearch={onSearch}
        />
        <Table {...tableProps} columns={columns} dataSource={dataList} />
      </div>
      <DealerForm data={currentData} setData={setCurrentData} isOpen={isOpenForm} handleCancel={handleClose} />
    </>
  )
}
