import { Api } from "./APIconfig/Api";

export const loginAPI = async (payload) => {
    return await Api.post("/users/login", payload);
}

export const getUserInfoAPI = async () => {
    return await Api.post("/users/info", {});
}

export const deleteUserAPI = async (userID) => {
    return await Api.delete("/users/"+userID);
}
