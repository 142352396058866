import * as React from 'react';
import { useNavigate, useOutlet } from 'react-router-dom';
import {  deleteUserAPI, getUserInfoAPI } from '../@lib/APIs/authAPI';

export const AuthContext = React.createContext(null);

const AuthProvider = () => {
    const [loginStatus, setLoginStatus] = React.useState(false);
    const [user, setUser]= React.useState({});
    let navigate = useNavigate();
    const children = useOutlet();

    const saveUser = (user) => {
        localStorage.setItem('token', user.token);
        setUser(user);
        setLoginStatus(true);
    };

    const delUser = () => {
        localStorage.removeItem('token');
        setUser({});
        setLoginStatus(false);
        // deleteUserAPI()
        return navigate( + '/dang-nhap');

    };

    React.useEffect(() => {
        getMe()
    }, []);

    const getMe = async () => {
        const token = localStorage.getItem('token');
        if (token == undefined || token == null || token == '') {
            return navigate(  '/dang-nhap');
        }
        try {
            const res = await getUserInfoAPI();
            if (res.status == 200) {
                const data = res.data;
                const newUser = {
                    ...data,
                    token: localStorage.getItem('token')
                }
                saveUser(newUser)
                return newUser;
            } else {
                return navigate( '/dang-nhap');
            }
        } catch (error) {
            console.log(error);
            if(error.response.status == '401'){
                delUser();
            }
            return navigate( '/dang-nhap');
        }
    }
    return <AuthContext.Provider value={{ setLoginStatus, loginStatus, user, saveUser, delUser, getMe }}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
