import React, { useEffect, useState } from "react";
import {
    Form,
    Input,
    Breadcrumb,
    Button,
    theme,
    DatePicker,
    Row,
    Col,
    Switch,
    message,
} from "antd";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { createMatchAPI } from "../../@lib/APIs/manageAPI";
import { routersList } from "../../@mock/routers";
export const Create = () => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const [messageApi, contextHolder] = message.useMessage();

    let navigate = useNavigate();
    const [form] = Form.useForm();
    const [, forceUpdate] = useState({});
    const [isDisableForm, setIsDisableForm] = useState(false);
    const [dataForm, setDataForm] = useState({
        schedule: (new Date()).toISOString(),
        is_top: false,
        is_live: false,
        prior: false,
        live_integrated: false
    });
    // To disable submit button at the beginning.
    useEffect(() => {
        forceUpdate({});
    }, []);
    const onFinish = async (values) => {
        setIsDisableForm(true);
        let data = { ...values, ...dataForm };
        data.prior = data.prior ? "True" : "False";
        try {
            let res = await createMatchAPI(data);
            if ((res.status = 200)) {
                navigate(routersList.matchManager);
            }
        } catch (error) {
            console.log(error);
        }
        setIsDisableForm(false)
    };

    const onChange = (value, dateString) => {
        setDataForm({ ...dataForm, schedule: value.toISOString() });
    };
    return (
        <div>
            {contextHolder}
            <Breadcrumb
                style={{
                    margin: "16px 0",
                }}
            >
                <Breadcrumb.Item>Quản lý</Breadcrumb.Item>
                <Breadcrumb.Item>Tạo trận đấu</Breadcrumb.Item>
            </Breadcrumb>
            <div
                style={{
                    padding: 24,
                    minHeight: 360,
                    background: colorBgContainer,
                }}
            >
                <Form
                    form={form}
                    name="horizontal_login"
                    type="primary"
                    layout="vertical"
                    onFinish={onFinish}
                    disabled = {isDisableForm}
                >
                    <Row gutter={[16, 16]}>
                        <Col md={12}>
                            <Form.Item name="title" label="Tiêu đề" rules={[
                                {
                                    required: true,
                                    message: "Tiêu đề bóng là bắt buộc!",
                                },
                            ]}>
                                <Input placeholder="tiêu đề trận đấu" />
                            </Form.Item>
                        </Col>
                        <Col md={12}>
                            <Form.Item name="description" label="Giải">
                                <Input placeholder="Giải" />
                            </Form.Item>
                        </Col>
                    </Row>
                    {/* Team 1 */}
                    <Row gutter={[16, 16]}>
                        <Col md={12}>
                            <Form.Item
                                name="team_1"
                                label="Tên Đội 1"
                                rules={[
                                    {
                                        required: true,
                                        message: "Tên đội bóng là bắt buộc!",
                                    },
                                ]}
                            >
                                <Input type="text" placeholder="Tên Đội 1" />
                            </Form.Item>
                        </Col>
                        <Col md={12}>
                            <Form.Item
                                name="team_1_logo"
                                label="Logo Đội 1"
                                rules={[
                                    {
                                        required: true,
                                        message: "Logo đội bóng là bắt buộc!",
                                    },
                                ]}
                            >
                                <Input type="text" placeholder="logo Đội 1" />
                            </Form.Item>
                        </Col>
                    </Row>

                    {/* Team 2 */}
                    <Row gutter={[16, 16]}>
                        <Col md={12}>
                            <Form.Item
                                name="team_2"
                                label="Tên Đội 2"
                                rules={[
                                    {
                                        required: true,
                                        message: "Tên đội bóng là bắt buộc!",
                                    },
                                ]}
                            >
                                <Input type="text" placeholder="Tên Đội 2" />
                            </Form.Item>
                        </Col>
                        <Col md={12}>
                            <Form.Item
                                name="team_2_logo"
                                label="Logo Đội 2"
                                rules={[
                                    {
                                        required: true,
                                        message: "Logo đội bóng là bắt buộc!",
                                    },
                                ]}
                            >
                                <Input type="text" placeholder="Logo Đội 2" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col md={12}>
                            <Form.Item
                                name="source_live"
                                label="Link nhà đài"
                            >
                                <Input type="text" placeholder="Nhập link nhà đài ở đây" />
                            </Form.Item>
                        </Col>
                        <Col md={6}>
                            <Form.Item name="live_integrated" label="Tích hợp Live (Live + Nhà đài)">

                                <Switch checked={dataForm.live_integrated} onClick={() => setDataForm({ ...dataForm, live_integrated: !dataForm.live_integrated })} />
                            </Form.Item>
                        </Col>
                        <Col md={6}>
                            <Form.Item name="prior" label="Siêu hot?">
                                <Switch checked={dataForm.prior} onClick={() => setDataForm({ ...dataForm, prior: !dataForm.prior })} />
                            </Form.Item>
                        </Col>
                    </Row>
                    
                    <Row gutter={[16, 16]}>
                        <Col md={12}>
                            <Form.Item name="stadium" label="Sân vận động">
                                <Input type="text" placeholder="Sân vận động" />
                            </Form.Item>
                        </Col>
            
                        <Col md={12}>

                            <Form.Item name="commentator" label="BLV">
                                <Input
                                    type="text"
                                    placeholder="Tên bình luận viên"
                                />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col md={12}>
                            <Form.Item name="schedule" label="Ngày phát sóng">
                                <DatePicker showTime onChange={onChange} defaultValue={dayjs(dataForm.schedule)} style={{
                                    width: "100%",
                                }} />
                            </Form.Item>
                        </Col>
                        <Col md={6}>

                            <Form.Item name="is_top" label="Đầu trang (Trận đấu hot?)">

                                <Switch checked={dataForm.is_top} onClick={() => setDataForm({ ...dataForm, is_top: !dataForm.is_top })} />
                            </Form.Item>
                        </Col>
                        <Col md={6}>
                            <Form.Item name="is_live" label="Phát trực tiếp">

                                <Switch checked={dataForm.is_live} onClick={() => setDataForm({ ...dataForm, is_live: !dataForm.is_live })} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item shouldUpdate>
                        <Button type="primary" htmlType="submit">
                            Thêm trận đấu
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};
