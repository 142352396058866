import React, { useState } from 'react'
import { Breadcrumb, Button, Col, Form, Input, Modal, Popconfirm, Row, Select, message, theme } from 'antd';
import { Space, Table } from 'antd';
import { createNodeAPI, deleteNodeAPI, getNodeListAPI, updateNodeAPI } from '../../@lib/APIs/nodeAPI';

const optionSelect = [
    {
        label: 'node',
        value: 'node',
    },
]

export const NodeManager = () => {
    const { token: { colorBgContainer }, } = theme.useToken();
    const [dataListTop, setDataListTop] = React.useState([]);
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [formCreate] = Form.useForm();
    const [formUpdate] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentNode, setCurrentNode] = useState(false);

    const showModal = (record) => {
        setCurrentNode(record);
        formUpdate.setFieldsValue({
            ip: record.ip,
            node_type: record.node_type
        })

        setIsModalOpen(true);
    };
    const handleUpdate = async (values) => {
        try {
            let res = await updateNodeAPI(currentNode.id, values);
            if (res.status === 200) {
                formUpdate.setFieldsValue({ ip: "" })
                setDataListTop(dataListTop.map((item) => {
                    if (item.id === currentNode.id) {
                        return {
                            id: item.id,
                            ...values
                        }
                    }
                    return item;
                }))
                messageApi.open({
                    type: 'success',
                    content: 'Cập nhật node thành công',
                });
                handleCancel();
            }
        } catch (error) {
            console.log(error);
            errorMess(error);
        }
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const columns = [
        {
            title: 'IP',
            dataIndex: 'ip',
            key: 'ip'
        },
        {
            title: 'Loại Node',
            dataIndex: 'node_type',
            key: 'node_type'
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return (
                    <Space size="middle">
                        <a href='#' onClick={() => showModal(record)} >Edit</a>

                        <Popconfirm
                            description="Xóa node này?"
                            onConfirm={() => removeItem(record.id)}
                            okText="Xóa"
                            cancelText="Hủy"
                        >
                            <span style={{ color: "red", cursor: "pointer" }}>Xóa</span>
                        </Popconfirm>
                    </Space>
                )
            },
        },
    ];

    const removeItem = async (idRecord) => {
        try {
            let res = await deleteNodeAPI(idRecord);
            if (res) {
                setDataListTop(dataListTop.filter(e => e.id != idRecord));
                messageApi.open({
                    type: 'success',
                    content: 'Xóa node thành công',
                });
            }
        } catch (error) {
            console.log(error);
            errorMess()
        }
    }

    const getList = async () => {
        setLoading(true)
        try {
            let res = await getNodeListAPI();
            if (res) {
                setDataListTop(res.data.data);
            }
        } catch (error) {
            console.log(error);
            errorMess()
        }
        setLoading(false)
    }
    const errorMess = (error) => {
        messageApi.open({
            type: 'error',
            content: error ?? 'Có lỗi xảy ra, thử lại sau.',
        });
    }
    React.useEffect(() => {
        getList();
        formCreate.setFieldsValue({
            node_type: 'node'
        })
    }, []);
    const onFinish = async (values) => {
        try {
            let res = await createNodeAPI(values);
            if (res.status === 200) {
                formCreate.setFieldsValue({
                    ip: ""
                })
                getList();
                messageApi.open({
                    type: 'success',
                    content: 'Tạo node thành công',
                });
            }
        } catch (error) {
            console.log(error);
            errorMess(error);
        }
    };
    const tableProps = { loading };

    return (
        <>
            {contextHolder}
            <Breadcrumb
                style={{
                    margin: '16px 0',
                }}
                items={[{ title: "Node Redis" },]}
            >
            </Breadcrumb>
            <div
                style={{
                    padding: 24,
                    minHeight: 360,
                    background: colorBgContainer,
                }}
            >
                <Form
                    form={formCreate}
                    onFinish={onFinish}
                    layout="vertical"
                >
                    <ContentForm form={formCreate} />
                    <Col md={6}>
                        <Form.Item shouldUpdate>
                            <Button type="primary" htmlType="submit">
                                Tạo Node
                            </Button>
                        </Form.Item>

                    </Col>
                </Form>
                <h2>Danh sách nodes</h2>
                <Table {...tableProps} columns={columns} dataSource={dataListTop} />
            </div>

            <Form
                form={formUpdate}
                layout="vertical"
                onFinish={handleUpdate}
            >
                <Modal title="Chi tiết" open={isModalOpen} onCancel={handleCancel} footer={[
                    <Button key="back" onClick={handleCancel}>Trở lại</Button>,
                    <Button key="submit" type="primary" htmlType="submit" onClick={() => formUpdate.submit()}>Cập nhật</Button>
                ]}
                >
                    <ContentForm />
                </Modal>
            </Form>

        </>
    )
}
const ContentForm = ({ form }) => {
    return (
        <Row gutter={[16, 16]}>
            <Col md={12}>
                <Form.Item name="ip" label="IP" rules={[
                    {
                        required: true,
                        message: "IP là bắt buộc!",
                    },
                ]}>
                    <Input placeholder="Nhập IP của Node" />
                </Form.Item>
            </Col>
            <Col md={12}>
                <Form.Item name="node_type" label="Loại Node">
                    <Select
                        onChange={(e) => {
                            form.setFieldsValue({
                                node_type: e
                            })
                        }}
                        options={optionSelect}
                    />
                </Form.Item>
            </Col>
        </Row>

    )
}