import  Axios from 'axios'
import { HOST_API } from '../../../@mock/constants';
import { ToastError } from '../../Toasts/toastConfig';
function authRequestInterceptor(config) {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers ={
            "Accept": "application/json",
            "Authorization": token
        };
    } else {
        config.headers = {
            "Accept": "application/json"
        }
    }
    return config;
}

export const Api = Axios.create({
    baseURL: HOST_API,
});

Api.interceptors.request.use(authRequestInterceptor);
Api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const message = error.response?.data?.message || error.message;
        console.log(error);
        ToastError(message);
        return Promise.reject(error);
    }
);