import React, { useEffect, useState } from "react";
import {
    Form,
    Input,
    Breadcrumb,
    Button,
    theme,
    DatePicker,
    Select,
    Row,
    Col,
    Switch,
    message,
    Space,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { HOST_API } from "../../@mock/constants";
import dayjs from "dayjs";
import { getDetailMatchAPI, resetStreamKeyAPI, updateDetailMatchAPI } from "../../@lib/APIs/manageAPI";
import { CopyOutlined } from '@ant-design/icons';

export const UpdateMatch = () => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const [messageApi, contextHolder] = message.useMessage();
    const [isDisableForm, setIsDisableForm] = useState(false);

    let navigate = useNavigate();
    let { matchId } = useParams();
    const [form] = Form.useForm();
    const [formScore] = Form.useForm();
    const [, forceUpdate] = useState({});
    const [dataForm, setDataForm] = useState({
        schedule: "",
        is_top: false,
        is_live: false,
        prior: false,
        live_integrated: false,
        // stadium: "",
    });

    const handleCopyStreamKey = () => {
        navigator.clipboard.writeText(form.getFieldValue("stream_key"))
        messageApi.open({
            type: 'success',
            content: 'Copy stream key thành công',
        });
    }

    const handleResetStreamKey = async () => {
        setIsDisableForm(true);
        try {
            let streamKey = form.getFieldValue("stream_key");
            let resResetKey = await resetStreamKeyAPI(streamKey);
            if (resResetKey.status == 200) {
                getDetailMatch();
                messageApi.open({
                    type: 'success',
                    content: 'Reset stream key thành công',
                });
            }
        } catch (error) {
            messageApi.open({
                type: 'error',
                content: 'Có lỗi xảy ra, thử lại sau',
            });
            console.log(error);
        }
        setIsDisableForm(false)
    }
    const getDetailMatch = async () => {
        try {
            let res = await getDetailMatchAPI(matchId)
            // eslint-disable-next-line no-cond-assign
            if (res && res.data.total > 0) {
                let detailRes = res.data.data[0];
                form.setFieldsValue({
                    title: detailRes.title,
                    team_1: detailRes.team_1,
                    team_2: detailRes.team_2,
                    description: detailRes.description,
                    team_1_logo: detailRes.team_1_logo,
                    team_2_logo: detailRes.team_2_logo,
                    commentator: detailRes.commentator,
                    stadium: detailRes.stadium,
                    crawl_live: detailRes.crawl_live,
                    stream_key: detailRes.stream_key,
                    source_live: detailRes.source_live
                })

                setDataForm({
                    is_live: detailRes.is_live,
                    is_top: detailRes.is_top,
                    live_integrated: detailRes.live_integrated,
                    schedule: detailRes.schedule + 'Z',
                    prior: detailRes.prior == "True" ? true : false
                })

                formScore.setFieldsValue({
                    team_1_score: detailRes.score.team_1_score,
                    team_2_score: detailRes.score.team_2_score,
                })

            } else {
                alert("Đã xảy ra lỗi. Không thể tải chi tiết trận đấu. Thử lại sau.")
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getDetailMatch()
    }, []);
    const onFinish = async (values) => {
        setIsDisableForm(true)
        let data = { ...values, ...dataForm };
        data.prior = data.prior ? "True" : "False";

        try {

            let res = await updateDetailMatchAPI(matchId, data);

            if ((res.status = 200)) {
                alert("Thành công")
            }
        } catch (error) {
            console.log(error);
        }
        setIsDisableForm(false)
    };
    const handleUpdateScore = async (values) => {
        setIsDisableForm(true)
        let data = {
            "score": {
                "team_1_score": parseInt(values.team_1_score),
                "team_2_score": parseInt(values.team_2_score),
                "detail": {}
            },
        };
        try {
            let res = await updateDetailMatchAPI(matchId, data);
            if ((res.status = 200)) {
                alert("Thành công")
            }
        } catch (error) {
            console.log(error);
        }
        setIsDisableForm(false)
    };

    const onChange = (value, dateString) => {
        setDataForm({ ...dataForm, schedule: value.toISOString() });
    };

    return (
        <div>
            {contextHolder}
            <Breadcrumb
                style={{
                    margin: "16px 0",
                }}
            >
                <Breadcrumb.Item>Quản lý</Breadcrumb.Item>
                <Breadcrumb.Item>Chi tiết trận đấu</Breadcrumb.Item>
            </Breadcrumb>
            <div
                style={{
                    padding: 24,
                    minHeight: 360,
                    background: colorBgContainer,
                }}
            >
                <Form
                    form={form}
                    name="horizontal_login"
                    type="primary"
                    layout="vertical"
                    onFinish={onFinish}
                    disabled = {isDisableForm}
                >
                    <Row gutter={[16, 16]}>
                        <Col md={12}>
                            <Form.Item name="title" label="Tiêu đề"
                                rules={[
                                    {
                                        required: true,
                                        message: "Tiêu đề bóng là bắt buộc!",
                                    },
                                ]}>
                                <Input placeholder="tiêu đề trận đấu" />
                            </Form.Item>
                        </Col>
                        <Col md={12}>
                            <Form.Item name="description" label="Giải">
                                <Input placeholder="Giải" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col lg={19}>
                            <Form.Item name="stream_key" label="Stream key">
                                <Input
                                    disabled
                                    type="text"
                                />
                            </Form.Item>

                        </Col>
                        <Col lg={4}>
                            <Form.Item label=" ">
                                <Space size="middle">

                                    <Button onClick={handleResetStreamKey}>Reset Key</Button>
                                    <Button onClick={handleCopyStreamKey}>Copy<CopyOutlined /></Button>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                    {/* Team 1 */}
                    <Row gutter={[16, 16]}>
                        <Col md={12}>
                            <Form.Item
                                name="team_1"
                                label="Tên Đội 1"
                                rules={[
                                    {
                                        required: true,
                                        message: "Tên đội bóng là bắt buộc!",
                                    },
                                ]}
                            >
                                <Input type="text" placeholder="Tên Đội 1" />
                            </Form.Item>
                        </Col>
                        <Col md={12}>
                            <Form.Item
                                name="team_1_logo"
                                label="Logo Đội 1"
                                rules={[
                                    {
                                        required: true,
                                        message: "Logo đội bóng là bắt buộc!",
                                    },
                                ]}
                            >
                                <Input type="text" placeholder="logo Đội 1" />
                            </Form.Item>
                        </Col>
                    </Row>

                    {/* Team 2 */}
                    <Row gutter={[16, 16]}>
                        <Col md={12}>
                            <Form.Item
                                name="team_2"
                                label="Tên Đội 2"
                                rules={[
                                    {
                                        required: true,
                                        message: "Tên đội bóng là bắt buộc!",
                                    },
                                ]}
                            >
                                <Input type="text" placeholder="Tên Đội 2" />
                            </Form.Item>
                        </Col>
                        <Col md={12}>
                            <Form.Item
                                name="team_2_logo"
                                label="Logo Đội 2"
                                rules={[
                                    {
                                        required: true,
                                        message: "Logo đội bóng là bắt buộc!",
                                    },
                                ]}
                            >
                                <Input type="text" placeholder="Logo Đội 2" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        {/* <Col md={12}>
                            <Form.Item
                                name="crawl_live"
                                label="Link đi cào"
                            >
                            <Input type="text" placeholder="Nhập link trận đấu cào được ở đây" />
                            </Form.Item>
                        </Col> */}
                        <Col md={12}>
                            <Form.Item
                                name="source_live"
                                label="Link nhà đài"
                            >
                                <Input type="text" placeholder="Nhập link nhà đài ở đây" />
                            </Form.Item>
                        </Col>
                        <Col md={6}>
                            <Form.Item name="live_integrated" label="Tích hợp Live (Live + Nhà đài)">

                                <Switch checked={dataForm.live_integrated} onClick={() => setDataForm({ ...dataForm, live_integrated: !dataForm.live_integrated })} />
                            </Form.Item>
                        </Col>
                        <Col md={6}>
                            <Form.Item name="prior" label="Siêu hot?">
                                <Switch checked={dataForm.prior} onClick={() => setDataForm({ ...dataForm, prior: !dataForm.prior })} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col md={12}>
                            <Form.Item name="stadium" label="Sân vận động">
                                <Input type="text" placeholder="Sân vận động" />
                            </Form.Item>
                        </Col>

                        <Col md={12}>
                            <Form.Item name="commentator" label="BLV">
                                <Input
                                    type="text"
                                    placeholder="Tên bình luận viên"
                                />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col md={12}>
                            <Form.Item name="schedule" label="Ngày phát sóng">
                                {dataForm.schedule != '' && <DatePicker showTime onChange={onChange} defaultValue={dayjs(dataForm.schedule)} style={{
                                    width: "100%",
                                }} />}
                            </Form.Item>
                        </Col>
                        <Col md={6}>

                            <Form.Item name="is_top" label="Đầu trang (Trận đấu hot?)">

                                <Switch checked={dataForm.is_top} onClick={() => setDataForm({ ...dataForm, is_top: !dataForm.is_top })} />
                            </Form.Item>
                        </Col>
                        <Col md={6}>
                            <Form.Item name="is_live" label="Phát trực tiếp">

                                <Switch checked={dataForm.is_live} onClick={() => setDataForm({ ...dataForm, is_live: !dataForm.is_live })} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item shouldUpdate>
                        <Button type="primary" htmlType="submit">
                            Cập nhật
                        </Button>
                    </Form.Item>
                </Form>
            </div>


            <div
                style={{
                    marginTop: 10,
                    padding: 24,
                    background: colorBgContainer,
                }}
            >
                <Form
                    form={formScore}
                    name="horizontal_login"
                    type="primary"
                    layout="vertical"
                    onFinish={handleUpdateScore}
                    disabled={isDisableForm}
                >
                    <h4>Cập nhật tỷ số</h4>
                    {/* Team 1 */}
                    <Row gutter={[16, 16]}>
                        <Col md={12}>
                            <Form.Item
                                name="team_1_score"
                                label=" Đội 1"
                                rules={[
                                    {
                                        required: true,
                                        message: "Điểm số đội bóng là bắt buộc!",
                                    },
                                ]}
                            >
                                <Input type="number" placeholder="Điểm số Đội 1" />
                            </Form.Item>
                        </Col>
                        <Col md={12}>
                            <Form.Item
                                name="team_2_score"
                                label="Đội 2"
                                rules={[
                                    {
                                        required: true,
                                        message: "Điểm số đội bóng là bắt buộc!",
                                    },
                                ]}
                            >
                                <Input type="number" placeholder="Điểm số Đội 1" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item shouldUpdate>
                        <Button type="primary" htmlType="submit">
                            Cập nhật
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};
