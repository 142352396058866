import { createBrowserRouter } from "react-router-dom";
import { Dashboard } from "../../pages/Dashboard";
import { Manager } from "../../pages/managers/Manager";
import { Create } from "../../pages/managers/createMatch";
import { UpdateMatch } from "../../pages/managers/updateMatch";
import Layouts from "../../components/layout";
import Login from "../../pages/auth/login";
import AuthProvider from "../../contexts/AuthContext";
import { ListAdv } from "../../pages/advertisements/listAdv";
import { CreateOrUpdateAdv } from "../../pages/advertisements/createOrUpdateAdv";
import { ListMedia } from "../../pages/medias/mediaList";
import { NewMedia } from "../../pages/medias/newMedia";
import { NodeManager } from "../../pages/nodeRedis/nodeManager";
import { RedisNodeManager } from "../../pages/nodeRedis/redisNodeManager";
import { Dealers } from "../../pages/Dealers";
import { UserManager } from "../../pages/userManager";
import DomainsManager from "../../pages/domains/dns";
import { DNSManager } from "../../pages/domains/dns/dnsManager";
import { LivePageManager } from "../../pages/domains/livePages";
import { Blogs } from "../../pages/Blogs";

// import Root, { rootLoader } from "./routes/root";
// import Team, { teamLoader } from "./routes/team";

export const routers = createBrowserRouter([
     {
        path: "/",
        element: <AuthProvider />,
        children: [
            {
                path: 'dang-nhap',
                element: <Login />,
                // loader: teamLoader,
            },
            {
                element: <Layouts />,
                children: [
                    {
                        index: true,
                        element: <Dashboard />,
                        // loader: teamLoader,
                    },
                    {
                        path: "tran-dau",
                        // element: <Manager />,
                        // loader: teamLoader,
                        children: [
                            {
                                index: true,
                                element: <Manager />,
                                // loader: teamLoader,
                            },
                            {
                                path: 'them-moi',
                                element: <Create />,
                            },
                            {
                                path: ':matchId',
                                element: <UpdateMatch />,
                            }
                        ]
                    },
                    {
                        path: "quang-cao/:page",
                        children: [
                            {
                                index: true,
                                element: <ListAdv />,
                                // loader: teamLoader,
                            },
                            {
                                path: 'them-moi',
                                element: <CreateOrUpdateAdv />,
                            },
                            {
                                path: ':advId',
                                element: <CreateOrUpdateAdv />,
                            }
                        ]
                    },
                    {
                        path: "dai-ly",
                        children: [
                            {
                                index: true,
                                element: <Dealers />,
                                // loader: teamLoader,
                            }
                        ]
                    },
                    {
                        path: "blogs",
                        children: [
                            {
                                index: true,
                                element: <Blogs />,
                                // loader: teamLoader,
                            },
                        ]
                    },
                    {
                        path: "media",
                        children: [
                            {
                                index: true,
                                element: <ListMedia />,
                                // loader: teamLoader,
                            },
                            {
                                path: 'them-moi',
                                element: <NewMedia />,
                            }
                        ]
                    },
                    {
                        path: "node",
                        children: [
                            {
                                index: true,
                                element: <NodeManager />,
                                // loader: teamLoader,
                            },
                        ]
                    },
                    {
                        path: "redis_node",
                        children: [
                            {
                                index: true,
                                element: <RedisNodeManager />,
                                // loader: teamLoader,
                            },
                        ]
                    },
                    {
                        path: "user-manager",
                        children: [
                            {
                                index: true,
                                element: <UserManager />,
                                // loader: teamLoader,
                            },
                        ]
                    },
                    // {
                    //     path: "domains",
                    //     children: [
                    //         {
                    //             index: true,
                    //             element: <DomainsManager />,
                    //             // loader: teamLoader,
                    //         },
                    //         {
                    //             path: ':domainId',
                    //             element: <DNSManager />,
                    //         },
                    //     ]
                    // },
                    // {
                    //     path: "live-page",
                    //     children: [
                    //         {
                    //             index: true,
                    //             element: <LivePageManager />,
                    //             // loader: teamLoader,
                    //         }
                    //     ]
                    // },
        
                ],
            },
        ],
    },
]);
