import { Api } from "./APIconfig/Api";

export const getListAdvAPI = async (category, page, limit) => {
    return await Api.post("/banners/graph", {
        order_asc: "prior",
        queries: [
            { "field": "category", "type": "equal", "value": category },
        ],
        limit, page
    } );
}

export const getDetailAdvAPI = async (bannerId)=>{
    return await Api.get("/banner/"+bannerId)
}

export const createAdvAPI = async (payload) => {
    return await Api.post("/banners" , payload);
}
export const updateDetailAdvAPI = async (bannerId, payload) => {
    return await Api.patch("/banner/" + bannerId, payload);
}
export const updateStatusAdvAPI = async (bannerId, payload) => {
    return await Api.patch("/banner/" + bannerId + "/status", payload);
}
export const deleteAdvAPI = async (bannerId) => {
    return await Api.delete("/banner/" + bannerId);
}