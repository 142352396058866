import { App, Button, Checkbox, Form, Input } from 'antd';
import AuthProvider, { AuthContext } from '../../contexts/AuthContext';
import { loginAPI } from '../../@lib/APIs/authAPI';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';


const Login = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { notification } = App.useApp();
  const {saveUser} = useContext(AuthContext)
  let navigate = useNavigate();

  const onFinish = async (values) => {
    try {
      let res = await loginAPI(values);
      if (res.status == 200) {
        saveUser({
          username: values.username,
          token: res.data?.access_token
        });
        navigate("/");
      }
    } catch (error) {
      console.log(error);
      // notification.error({
      //   message: `Có lỗi xảy ra!!`,
      //   description: error,
      //   placement: 'topRight',
      // });
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return (
      <div
        style={{ width: "100%", height: "100vh", display: 'flex', alignItems: "center", justifyContent: "center" }}
      >

        <Form
          name="basic"
          style={{
            maxWidth: 600,
            width: "100%",
            padding: 25,
            border: "2px solid #7f7f7f57",
            borderRadius: '10px'
          }}
          // initialValues={{
          //   remember: true,
          // }}                    
          type="primary"
          layout="vertical"

          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <h1>Đăng nhập</h1>
          <Form.Item
            label="Username"
            name="username"
            rules={[
              {
                required: true,
                message: 'Please input your username!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          {/* <Form.Item
        name="remember"
        valuePropName="checked"
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Checkbox>Remember me</Checkbox>
      </Form.Item> */}

          <Form.Item
          // wrapperCol={{
          //   offset: 8,
          //   span: 16,
          // }}
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
  );
}
export default Login;