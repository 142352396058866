// export const HOST_API = "http://103.106.105.43:8000";
export const HOST_API = "https://api.khandaia.wiki";
export const HOST_WS_API = "https://ws.gavang.tech";
export const CHAT_BOX_LINK = "https://ws-client.gavang.tech/";
// export const STATS_LINK = "https://stats.gavang.tech/";
export const statusPageList = {
    create: 0,
    update: 1
}
export const FilterConstants = {
    All: -1,
    BLV: 0,
    Hot: 1,
    Live: 2,
    Top:3
  }
