import React, { useEffect, useState } from "react";
import {
    Form,
    Input,
    Breadcrumb,
    Button,
    theme,
    Row,
    Col,
    Select,
    message,
} from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { routersList } from "../../@mock/routers";
import { createAdvAPI, getDetailAdvAPI, updateDetailAdvAPI } from "../../@lib/APIs/advertisementAPI";
import { statusPageList } from "../../@mock/constants";

export const CreateOrUpdateAdv = () => {
    const { token: { colorBgContainer }, } = theme.useToken();
    const [isDisableForm, setIsDisableForm] = useState(false);
    let navigate = useNavigate();
    const location = useLocation();
    const [form] = Form.useForm();
    const [, forceUpdate] = useState({});
    const [statusPage, setStatusPage] = useState(statusPageList.create);
    const [messageApi, contextHolder] = message.useMessage();
    let { advId, page } = useParams();

    const getDetail = async () => {
        try {
            let resDetail = await getDetailAdvAPI(advId);
            if (resDetail) {
                form.setFieldsValue({
                    image_url: resDetail.data.image_url,
                    refer: resDetail.data.refer,
                    status: resDetail.data.status ,
                    prior: resDetail.data.prior,
                    code: resDetail.data.code,
                })
            }
        } catch (error) {
            
        }
    }

    useEffect(() => {
        forceUpdate({});
        if (location.pathname != routersList.advCreate(page)) {
            setStatusPage(statusPageList.update);
            getDetail();
        }
    }, []);

    const onFinish = async (values) => {
        setIsDisableForm(true)
        let data = {
            "code": "#FFBF00",
            // "source": "",
            // "status": "",
            "category": page,
            ...values
        }
        data.code = data.code ?? "#FFBF00";
        data.status = data.status ?? "public";
        try {
            if (statusPage === statusPageList.create) {
                let resCreate = await createAdvAPI(data);
                if (resCreate) {
                    messageApi.open({
                        type: 'success',
                        content: 'Thêm mới quảng cáo thành công',
                    });
                    navigate(routersList.advManager);
                }
            }
            if (statusPage === statusPageList.update) {
                let resUpdate = await updateDetailAdvAPI(advId, data);
                if (resUpdate) {
                    messageApi.open({
                        type: 'success',
                        content: 'Cập nhật quảng cáo thành công',
                    });
                }
            }
        } catch (error) {
            console.log(error);
            messageApi.open({
                type: 'error',
                content: 'Có lỗi xảy ra, thử lại sau.',
            });
        }
        setIsDisableForm(false)
    };

    return (
        <div>
            {contextHolder}

            <Breadcrumb
                style={{
                    margin: "16px 0",
                }}
            >
                <Breadcrumb.Item>Quảng cáo</Breadcrumb.Item>
                <Breadcrumb.Item>Thêm mới quảng cáo</Breadcrumb.Item>
            </Breadcrumb>
            <div
                style={{
                    padding: 24,
                    minHeight: 360,
                    background: colorBgContainer,
                }}
            >
                <Form
                    form={form}
                    name="adv"
                    type="primary"
                    layout="vertical"
                    onFinish={onFinish}
                    disabled={isDisableForm}
                >
                    <Row gutter={[16, 16]}>
                        <Col md={24}>
                            <Form.Item name="image_url" label="Link Banner/Tiêu đề nút bấm" rules={[
                                {
                                    required: true,
                                    message: "Trường này là bắt buộc!",
                                },
                            ]}>
                                <Input placeholder="Điền link ảnh vào đây" />
                            </Form.Item>
                        </Col>
                        <Col md={24}>
                            <Form.Item name={'refer'} label="Trang đích" rules={[
                                {
                                    required: true,
                                    message: "Trang đích là bắt buộc!",
                                },
                            ]}>
                                <Input placeholder="Điền link trang đích của quảng cáo vào đây" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col md={8}>
                            <Form.Item name={'code'} label="Màu sắc (Cho các nút)">
                                <Input defaultValue={"#FFBF00"} type="color"  />
                            </Form.Item>
                        </Col>
                        <Col md={8}>
                            <Form.Item name={'prior'} label="Độ ưu tiên"
                                rules={[
                                    {
                                        required: true,
                                        message: "Link banner là bắt buộc!",
                                    },
                                    ({ getFieldValue }) => ({
                                        validator: (_, value) => {
                                            return parseInt(value) > 0 ? Promise.resolve() : Promise.reject(new Error('Độ ưu tiên phải lớn hơn 0'));
                                        },
                                    })
                                ]}>
                                <Input defaultValue={0} type="number" />
                            </Form.Item>
                        </Col>
                        <Col md={8}>
                            <Form.Item name={'status'} label="Trạng thái">
                                <Select defaultValue={"public"} options={[
                                    { value: 'public', label: 'Hiện' },
                                    { value: 'hidden', label: 'Ẩn' },
                                ]} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item shouldUpdate>
                        {statusPage == statusPageList.create && <Button type="primary" htmlType="submit">Thêm mới</Button>}
                        {statusPage == statusPageList.update &&  <Button type="primary" htmlType="submit">Cập nhật</Button>}
                    </Form.Item>
                </Form>
            <div>
                    <strong>Chú ý:</strong>
                    <ul>
                        <li>Độ ưu tiên đặc biệt: 30-39 dành cho các nút bấm</li>
                        <li>Màu sắc chỉ áp dụng cho nút bấm</li>
                        <li>Đối với nút bấm thì ghi tiêu đề của nút bấm ở trường [Link Banner/Tiêu đề nút bấm]</li>
                    </ul>
            </div>
            </div>
        </div>
    );
};
