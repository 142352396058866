import { Api } from "./APIconfig/Api";

export const getListDealersAPI = async () => {
    return await Api.get("/dealers");
}
export const getListPostAPI = async () => {
    return await Api.get("/dealers?star=-1");
}
export const getListBottomPostAPI = async () => {
    return await Api.get("/dealers?star=-2");
}

export const createDealerAPI = async (payload) => {
    return await Api.post("/dealers" , payload);/*  */
}
export const updateDearlerAPI = async (id, payload) => {
    return await Api.patch("/dealer/" + id, payload);
}
export const deleteDearlerAPI = async (id) => {
    return await Api.delete("/dealer/" + id);
}