import React, { useState } from "react";
import {
    Form,
    Breadcrumb,
    Button,
    theme,
    message,
} from "antd";
import { InboxOutlined } from "@ant-design/icons"
import { uploadFileAPI } from "../../@lib/APIs/mediaAPI";
import Dragger from "antd/es/upload/Dragger";

export const NewMedia = () => {
    const { token: { colorBgContainer }, } = theme.useToken();
    const [fileList, setFileList] = useState({});
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [isDisableForm, setIsDisableForm] = useState(false);

    const props = {
        name: 'file',
        multiple: false,
        File: fileList,
        beforeUpload: () => false,
        onChange({ fileList }) {
            if (fileList.length > 1) {
                fileList.shift();
            }
            setFileList(fileList[0]);
        },
    };

    const onFinish = async () => {
        setIsDisableForm(true)
        try {
            if (!fileList?.originFileObj) return;
            let fileName = fileList.originFileObj.name.split('.');
            let extend = fileName.pop();
            fileName = fileName.join("");
            var newFileName = fileName.replaceAll(" ", "") + (new Date()).getTime() + "." + extend;
            var formData = new FormData();
            formData.append("file", fileList.originFileObj, newFileName);
            let resCreate = await uploadFileAPI(formData);
            if (resCreate) {
                messageApi.open({
                    type: 'success',
                    content: 'Updload file thành công',
                });
                setFileList({});
            }
        } catch (error) {
            console.log(error);
            messageApi.open({
                type: 'error',
                content: 'Có lỗi xảy ra, thử lại sau.',
            });
        }
        setIsDisableForm(false)
    };

    return (
        <div>
            {contextHolder}
            <Breadcrumb
                style={{
                    margin: '16px 0',
                }}
                items={[{ title: "Medias" }, { title: "Tải lên hình ảnh" },]}
            >
            </Breadcrumb>
            <div
                style={{
                    padding: 24,
                    minHeight: 360,
                    background: colorBgContainer,
                }}
            >
                <Form
                    form={form}
                    name="adv"
                    type="primary"
                    layout="vertical"
                    onFinish={onFinish}
                    disabled={isDisableForm}
                    style={{ display: "flex", flexDirection: "column", gap: "15px" }}
                >
                    <Dragger {...props}>
                        <p className="ant-upload-drag-icon"><InboxOutlined /></p>
                        <p className="ant-upload-text">Click hoặc kéo thả file vào đây để tải lên</p>
                    </Dragger>
                    <Form.Item shouldUpdate>
                        <Button type="primary" htmlType="submit">Thêm mới</Button>
                    </Form.Item>
                </Form>
                <div>
                </div>
            </div>
        </div>
    );
};
