import React, { useEffect } from 'react'
import { Breadcrumb, Col, Row, theme } from 'antd';
import { getCountChatConnection } from '../@lib/APIs/homeAPI';
import { CHAT_BOX_LINK, HOST_WS, STATS_LINK, idWeb } from '../@mock/constants';

export const Dashboard = () => {
    const { token: { colorBgContainer } } = theme.useToken();
    const [connection, setConnection] = React.useState();
    const webSocketRef = React.useRef(null);

    const handleGetCountChatConnection = async () => {
        try {
            let res = await getCountChatConnection();
            if (res.status === 200) {
                setConnection(res.data)
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        handleGetCountChatConnection();
        setInterval(handleGetCountChatConnection, 5000);
    }, []);

    return (
        <>
            <Breadcrumb
                style={{
                    margin: '16px 0',
                }}
                items={[{ title: "Trang chủ" }, { title: "Trạng thái chung" },]}
            >
            </Breadcrumb>
            <div
                style={{
                    padding: 24,
                    minHeight: 360,
                    background: colorBgContainer,
                }}
            >
                {/* <Row gutter={[16, 16]} style={{marginBottom: 5}}>
                    <Col md={24}>
                        <iframe id='chat-box' src={STATS_LINK} height={"100%"} width={"100%"} allowtransparency="yes" allow="autoplay" scrolling="auto" style={{ height: "600px" }}></iframe>
                    </Col>
                </Row> */}
                <Row gutter={[16, 16]}>
                    <Col md={12}>
                        <p> Số Connection hiện tại:  <strong>{connection?.clients_connected ?? 0}</strong> </p>
                    </Col>
                    <Col md={12}>
                        <iframe id='chat-box' src={CHAT_BOX_LINK} height={"100%"} width={"100%"} allowtransparency="yes" allow="autoplay" scrolling="auto" style={{ minHeight: "500px" }}></iframe>
                    </Col>
                </Row>
                {/* <Table columns={columns} dataSource={dataList} /> */}
            </div>
        </>
    )
}
