import { Button, Col, Form, Input, Modal, message, theme, Row } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { statusPageList } from "../../@mock/constants";
import TextArea from "antd/es/input/TextArea";
import { createDealerAPI, getListBottomPostAPI, getListPostAPI, updateDearlerAPI } from "../../@lib/APIs/dealersAPI";
import 'react-quill/dist/quill.snow.css';
import { toSlug } from "../../@lib/Hooks/ToSlug";
import { uploadFileAPI } from "../../@lib/APIs/mediaAPI";

import JoditEditor from 'jodit-react';


export const BottomBlogDialogFrom = ({ isOpen, handleOk, handleCancel }) => {
    const [form] = Form.useForm();
    const [isDisableForm, setIsDisableForm] = useState(false);
    const [data, setData] = useState({});
    const [statusPage, setStatusPage] = useState(statusPageList.create);
    const [messageApi, contextHolder] = message.useMessage();
    const [valueDesc, setValueDesc] = useState('');
    const descEditorRef = useRef(null);


    const config =
    {
        readonly: false,
        placeholder: 'Nhập nội dung vào đây.'
    }
    const onChangeTitle = () => {
        const title = form.getFieldValue("name");
        const slug = toSlug(title);
        if (slug) {
            form.setFieldValue("refer", slug);
        }
    }

    const onFinish = async (values) => {
        setIsDisableForm(true)
        // values.sumary = values.sumary.replaceAll("\n", "<br/>");
        values.star = -2;
        values.name = "Bottom Post"
        values.logo = "Bottom Post"
        values.refer = "Bottom Post"
        try {
            if (statusPage === statusPageList.create) {
                let resCreate = await createDealerAPI(values);
                if (resCreate) {
                    messageApi.open({
                        type: 'success',
                        content: 'Thêm mới đại lý thành công',
                    });
                }
                handleCancel();
            }
            if (statusPage === statusPageList.update) {
                let resUpdate = await updateDearlerAPI(data.id, values);
                if (resUpdate) {
                    messageApi.open({
                        type: 'success',
                        content: 'Cập nhật đại lý thành công',
                    });
                }
            }
        } catch (error) {
            console.log(error);
            messageApi.open({
                type: 'error',
                content: 'Có lỗi xảy ra, thử lại sau.',
            });
        }
        setIsDisableForm(false)
    };

    const getList = async () => {
        setIsDisableForm(true)
        if (!isOpen) return;
        try {
            let res = await getListBottomPostAPI();
            if (res.status == 200) {
                let resdata = res.data.data[0] ?? null
                setData(resdata)
                if (resdata) {
                    setStatusPage(statusPageList.update)
                } else {
                    setStatusPage(statusPageList.create)
                }
                form.resetFields();
                setValueDesc(resdata?.description);
                form.setFieldsValue({
                    description: resdata?.description,
                })
            }
        } catch (error) {
            console.log(error);
        }
        setIsDisableForm(false)
    }
    useEffect(() => {
        getList()
    }, [isOpen])

    useEffect(() => {

    }, [data])

    useEffect(() => {
        if (valueDesc == "<p><br></p>") {
            form.resetFields(["description"]);
            return
        }
        form.setFieldsValue({
            description: valueDesc,
        })
    }, [valueDesc])
    return (
        <Modal title={data?.name ?? "Thêm mới"} open={isOpen} footer={false} onCancel={handleCancel} width={1000} >
            {contextHolder}
            <Form
                form={form}
                type="primary"
                layout="vertical"
                onFinish={onFinish}
                disabled={isDisableForm}
            >
                {/* <Row gutter={[16, 16]}>
                    <Col md={24}>
                        <Form.Item name="name" label="Tiêu đề" rules={[
                            {
                                required: true,
                                message: "Tiêu đề là bắt buộc!",
                            },
                        ]}>
                            <Input placeholder="Điền Tiêu đề vào đây" onChange={onChangeTitle} />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item name={'refer'} label="URL bài viết" rules={[
                            {
                                required: true,
                                message: "URL là bắt buộc!",
                            },
                        ]}>
                            <Input placeholder="Điền URL bài viết vào đây" disabled />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item name={'logo'} label="Link thumbnail" rules={[
                            {
                                required: true,
                                message: "Link thumbnail là bắt buộc!",
                            },
                        ]}>
                            <Input placeholder="Điền link thumbnail của bài viết vào đây" />
                        </Form.Item>
                    </Col>
                </Row> */}
                {/* Not use */}
                {/* <Row gutter={[16, 16]} style={{ display: "none" }}>
                    <Col md={12}>
                        <Form.Item name={'prior'} label="Độ ưu tiên">
                            <Input defaultValue={0} type="number" />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item name={'star'} label="Đánh giá">
                            <Input defaultValue={0} type="number" />
                        </Form.Item>
                    </Col>
                </Row> */}

                <Row gutter={[16, 16]}>
                    {/* <Col md={24} >
                        <Form.Item name={'sumary'} label="Tóm tắt"
                            rules={[
                                {
                                    required: true,
                                    message: "Tóm tắt là bắt buộc!",
                                },
                            ]}>
                            <TextArea rows={4} placeholder="Điền tóm tắt đại lý vào đây" />
                        </Form.Item>
                    </Col> */}
                    <Col md={24} style={{ marginBottom: "20px" }} >
                        <Form.Item name={'description'} label="Chi tiết" style={{}}
                            rules={[
                                {
                                    required: true,
                                    message: "Chi tiết là bắt buộc!",
                                }
                            ]}
                        >
                            <TextArea rows={5} style={{ display: "none" }} />
                            <JoditEditor
                                ref={descEditorRef}
                                value={valueDesc}
                                config={config}
                                tabIndex={1}
                                onBlur={setValueDesc}
                            // onChange={newContent => {}}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item shouldUpdate >
                    <div style={{ display: "flex", gap: '15px', width: "100%", justifyContent: "flex-end" }}>
                        <Button type="dafault" htmlType="button" onClick={handleCancel} style={{ border: "1px solid black" }}>Hủy</Button>
                        {statusPage == statusPageList.create && <Button type="primary" htmlType="submit">Thêm mới</Button>}
                        {statusPage == statusPageList.update && <Button type="primary" htmlType="submit">Cập nhật</Button>}
                    </div>

                </Form.Item>
            </Form>
        </Modal>

    )
}