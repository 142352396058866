import { FileImageOutlined, PieChartOutlined, DesktopOutlined, NodeIndexOutlined, FundViewOutlined, ShopOutlined, UserOutlined, PartitionOutlined, ProfileOutlined } from '@ant-design/icons';
import { Button, Layout, Menu, theme } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useOutlet } from 'react-router-dom';
import  { AuthContext } from '../contexts/AuthContext';
import { routersList } from '../@mock/routers';
const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
    return {
        key,
        icon,
        children,
        label,
    };
}
const items = [
    getItem('Trang chủ', routersList.home, <PieChartOutlined />),
    getItem('Trận đấu', routersList.matchManager, <DesktopOutlined />),
    getItem('Quảng cáo', null, <FundViewOutlined />, [
        getItem('Khandaia.mobi', routersList.advManager.motam),
    ]),
    getItem('Đại lý', routersList.dealers, <ShopOutlined />),
    getItem('Blogs', routersList.blogs, <ProfileOutlined />),
    getItem('Media', routersList.mediaManager, <FileImageOutlined />),
    //getItem('Người dùng Chatbox', routersList.userManager, <UserOutlined />),
    // getItem('Tên miền', null, <PartitionOutlined />, [
    //     getItem('Domains', routersList.domainsManager),
    //     getItem('Live Page', routersList.livePageManager),
    // ]),
    getItem('Node', null, <NodeIndexOutlined />, [
        getItem('Node', routersList.node),
        getItem('Redis Node', routersList.nodeRedis),
    ]),
];

const Layouts = () => {
    const [collapsed, setCollapsed] = useState(false);
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    let navigate = useNavigate();

    const [currentPath, setCurrentPath] = useState('/')

    const { delUser, loginStatus } = useContext(AuthContext);

    const handleChange = (e) => {
        navigate(e.key);
    }

    const children = useOutlet();
    const location = useLocation();
    useEffect(() => {
        setCurrentPath('/' + location.pathname.split('/')[1])
    }, [])

    return (
        <>
            {loginStatus ?
                <>
                    <Layout hasSider
                        style={{
                            height: '100vh',
                        }}
                    >
                        <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} style={{ overflowY: "auto", height: "100vh", minWidth: '300px' }} >
                            <div style={{ display: 'flex', justifyContent: 'center', padding: 15 }}>
                                <img src="/images/logo.avif" style={{ width: "50%" }} alt='logo-gavang.tv' />
                            </div>

                            <Menu theme="dark" defaultSelectedKeys={[currentPath]} mode="inline" items={items} onClick={(e) => handleChange(e)} />
                        </Sider>
                        <Layout style={{ overflow: "auto", height: "100vh", width: '100%' }}>
                            <Header
                                style={{
                                    padding: 15,
                                    background: colorBgContainer,
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center"
                                }}
                            >
                                <Button onClick={() => delUser()}>Logout</Button>
                            </Header>
                            <Content
                                style={{
                                    margin: '0 16px',
                                }}
                            >
                                {children}
                                <Footer
                                    style={{
                                        textAlign: 'center',
                                    }}
                                >
                                    GavangTV ©2023
                                </Footer>
                            </Content>
                        </Layout>
                    </Layout>

                </> : null}
        </>
    );
};
export default Layouts;