import  Axios from 'axios'
import { HOST_WS_API } from '../../../@mock/constants';
import { ToastError } from '../../Toasts/toastConfig';
function authRequestInterceptor(config) {
    const token = localStorage.getItem('token');
    let configHeaders = {
        "Accept": "application/json",
        "X-Admin-Token" : '4I58KNdoOA0vTd3S0A5xAsnCGhqvhx8tsxpDeGWq'
    };
    if (token) {
        configHeaders.Authorization = token
    } 
    config.headers = configHeaders;
    return config;
}

export const WsApi = Axios.create({
    baseURL: HOST_WS_API,
});

WsApi.interceptors.request.use(authRequestInterceptor);
WsApi.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const message = error.response?.data?.message || error.message;
        // const code = error.response.status;
        console.log(error);
        ToastError(message);
        return Promise.reject(error);
    }
);