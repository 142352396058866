import React, { useState } from 'react'
import { Breadcrumb, Button, Col, Form, Input, Modal, Popconfirm, Row, Select, Switch, message, theme } from 'antd';
import { Space, Table } from 'antd';
import { createNodeAPI, createRedisNodeAPI, deleteNodeAPI, deleteRedisNodeAPI, getRedisNodeListAPI, getStatusRedis, updateNodeAPI, updateRedis, updateRedisNodeAPI } from '../../@lib/APIs/nodeAPI';

export const RedisNodeManager = () => {
    const { token: { colorBgContainer }, } = theme.useToken();
    const [dataListTop, setDataListTop] = React.useState([]);
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [formCreate] = Form.useForm();
    const [formUpdate] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentNode, setCurrentNode] = useState(false);
    const [statusRedis, setStatusRedis] = useState(false);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return (
                    <Space size="middle">
                        <a href='#' onClick={() => showModal(record)} >Edit</a>

                        <Popconfirm
                            description="Xóa node này?"
                            onConfirm={() => removeItem(record.id)}
                            okText="Xóa"
                            cancelText="Hủy"
                        >
                            <span style={{ color: "red", cursor: "pointer" }}>Xóa</span>
                        </Popconfirm>
                    </Space>
                )
            },
        },
    ];

    const showModal = (record) => {
        setCurrentNode(record);
        formUpdate.setFieldValue("name", record.name)
        setIsModalOpen(true);
    };
    const handleUpdate = async (values) => {
        try {
            let res = await updateRedisNodeAPI(currentNode.id, values);
            if (res.status === 200) {
                formUpdate.setFieldsValue({ name: "" })
                setDataListTop(dataListTop.map((item) => {
                    if (item.id === currentNode.id) {
                        return {
                            id: item.id,
                            ...values
                        }
                    }
                    return item;
                }))
                messageApi.open({
                    type: 'success',
                    content: 'Cập nhật node thành công',
                });
                handleCancel();
            }
        } catch (error) {
            console.log(error);
            errorMess(error);
        }
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const removeItem = async (idRecord) => {
        try {
            let res = await deleteRedisNodeAPI(idRecord);
            if (res) {
                setDataListTop(dataListTop.filter(e => e.id != idRecord));
                messageApi.open({
                    type: 'success',
                    content: 'Xóa node thành công',
                });
            }
        } catch (error) {
            console.log(error);
            errorMess()
        }
    }
    const handleUpdateStatsuRedis = async () => {
        try {
            let res = await updateRedis(!statusRedis);
            if (res) {
                setStatusRedis(!statusRedis)
            }
        } catch (error) {
            console.log(error);
            errorMess()
        }
    }
    const handlegetStatusRedis = async () => {
        try {
            let res = await getStatusRedis();
            if (res) {
                setStatusRedis(res.data.deleted);
            }
        } catch (error) {
            console.log(error);
            errorMess()
        }
    }
    const getList = async () => {
        setLoading(true)
        try {
            let res = await getRedisNodeListAPI();
            if (res) {
                setDataListTop(res.data.data);
            }
        } catch (error) {
            console.log(error);
            errorMess()
        }
        setLoading(false)
    }
    const errorMess = (error) => {
        messageApi.open({
            type: 'error',
            content: error ?? 'Có lỗi xảy ra, thử lại sau.',
        });
    }
    React.useEffect(() => {
        getList();
        handlegetStatusRedis();
    }, []);
    const onFinish = async (values) => {
        try {
            let res = await createRedisNodeAPI(values);
            if (res.status === 200) {
                formCreate.setFieldValue("name", "");
                getList();
                messageApi.open({
                    type: 'success',
                    content: 'Tạo node thành công',
                });
            }
        } catch (error) {
            console.log(error);
            errorMess(error);
        }
    };
    const tableProps = { loading };

    return (
        <>
            {contextHolder}
            <Breadcrumb
                style={{
                    margin: '16px 0',
                }}
                items={[{ title: "Node Redis" },]}
            >
            </Breadcrumb>
            <div
                style={{
                    padding: 24,
                    minHeight: 360,
                    background: colorBgContainer,
                }}
            >
                <Form
                    form={formCreate}
                    onFinish={onFinish}
                    layout="vertical"
                >
                    <ContentForm />
                    <Col md={6}>
                        <Form.Item shouldUpdate>
                            <Button type="primary" htmlType="submit">
                                Tạo Redis Node
                            </Button>
                        </Form.Item>

                    </Col>
                </Form>
                <Row gutter={[16, 16]}>
                    <Col md={12}>
                        <h2>Danh sách redis nodes</h2>
                    </Col>
                    <Col md={12} style={{ display: "flex", alignItems: "center", gap: '15px', justifyContent: "flex-end" }}>
                            <span>Enable CDN: </span>
                            <Switch checked={statusRedis} onClick={() => handleUpdateStatsuRedis()} />
                    </Col >
                </Row>
                <Table {...tableProps} columns={columns} dataSource={dataListTop} />
            </div>

            <Form
                form={formUpdate}
                layout="vertical"
                onFinish={handleUpdate}
            >
                <Modal title="Chi tiết" open={isModalOpen} onCancel={handleCancel} footer={[
                    <Button key="back" onClick={handleCancel}>Trở lại</Button>,
                    <Button key="submit" type="primary" htmlType="submit" onClick={() => formUpdate.submit()}>Cập nhật</Button>
                ]}
                >
                    <ContentForm />
                </Modal>
            </Form>

        </>
    )
}
const ContentForm = () => {
    return (
        <Row gutter={[16, 16]}>
            <Col md={24}>
                <Form.Item name="name" label="Tên Redis Node" rules={[
                    {
                        required: true,
                        message: "Tên Redis Node là bắt buộc!",
                    },
                ]}>
                    <Input placeholder="Nhập tên của Redis Node" />
                </Form.Item>
            </Col>
        </Row>

    )
}