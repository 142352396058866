import { Api } from "./APIconfig/Api";

export const uploadFileAPI = async (payload) => {
    return await Api.post("/upload", payload, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}
export const getFileListAPI = async () => {
    return await Api.get("/files");
}
export const deleteFileListAPI = async (fileName) => {
    return await Api.delete("/files/"+fileName);
}