import React from 'react'
import { Breadcrumb, Button, Card, Image, Popconfirm, Row, Space, Table, message, theme } from 'antd';
import { NavLink, useNavigate } from 'react-router-dom';
import { routersList } from '../../@mock/routers';
import { useState } from 'react';
import { deleteFileListAPI, getFileListAPI } from '../../@lib/APIs/mediaAPI';
import Search from 'antd/es/input/Search';

export const ListMedia = () => {
    const { token: { colorBgContainer }, } = theme.useToken();
    const [dataList, setDataList] = React.useState([]);
    let navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [dataListTop, setDataListTop] = React.useState([]);

    const columns = [
        {
            title: 'Files',
            key: 'file_name',
            dataIndex: 'file_name',
            width: "80%",
            render: (_, record) => {
                let isImage = !['mp4', 'm4a', 'mov'].includes(record.file_name.split(".").pop())
                return <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    {isImage ? <Image style={{ maxHeight: "250px", width: "auto", maxWidth: "100%" }} src={record.url} /> :
                        <video style={{ maxHeight: "250px" }} controls autoPlay={false}>
                            <source src={record.url} />
                        </video>}
                    <span style={{ fontWeight: 700 }}>{record.file_name}</span>

                </div>
            },
        },
        {
            title: 'Action',
            key: 'action',
            width: "20%",
            render: (_, record) => {
                return (
                    <Space size="middle" style={{}}>
                        <NavLink onClick={() => handleCopyLink(record.url)}> Copy Link </NavLink>
                        <Popconfirm
                            description="Xóa file này?"
                              onConfirm={() => removeMedia(record.file_name)}
                            // onCancel={cancel}
                            okText="Xóa"
                            cancelText="Hủy"
                        >
                            <span href='#' style={{ color: "red", cursor: "pointer" }}>Xóa</span>
                        </Popconfirm>
                    </Space>
                )
            },
        },
    ];
    const onSearch = () => {
        if (dataListTop.length == 0) {
            setDataList([]);
            return
        };
        const reg = new RegExp(searchText, "gi");
        const filteredData = dataListTop.map(record => {
            const nameMatch = record.file_name && record.file_name.match(reg);
            if (!nameMatch) {
                return null;
            }
            return record;
        }).filter(record => record != null);
        setDataList(filteredData);
    };

    const handleCopyLink = (link) => {
        const textArea = document.createElement("textarea");
        textArea.value = link;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            document.execCommand('copy');
            messageApi.open({
                type: 'success',
                content: 'Copy thành công',
            });
        } catch (err) {
            console.error('có lỗi xảy ra khi copy!', err);
            errorMess(err)
        }
        document.body.removeChild(textArea);
    }

    const getList = async () => {
        setLoading(true)
        try {
            let res = await getFileListAPI();
            if (res) {
                setDataListTop(res.data);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false)
    }
    const removeMedia = async (fileName) => {
        try {
            const resFile = await deleteFileListAPI(fileName);
            if (resFile) {
                let newdata = dataListTop.filter(e => e.file_name != fileName)
                setDataListTop(newdata);
        
            }
        } catch (error) {
            console.log(error);
        }
    }
    const errorMess = (error) => {
        messageApi.open({
            type: 'error',
            content: error ?? 'Có lỗi xảy ra, thử lại sau.',
        });
    }
    React.useEffect(() => {
        getList();
    }, [])
    React.useEffect(() => {
        onSearch();
    }, [dataListTop, searchText])

    const tableProps = {
        loading
    };
    return (
        <>
            {contextHolder}
            <Breadcrumb
                style={{
                    margin: '16px 0',
                }}
                items={[{ title: "Medias" }, { title: "Danh sách hình ảnh" },]}
            >
            </Breadcrumb>
            <div
                style={{
                    padding: 24,
                    minHeight: 360,
                    background: colorBgContainer,
                }}
            >
                <Row style={{ justifyContent: 'flex-end', padding: 10 }}>
                    <Button type="primary" onClick={() => navigate(routersList.mediaCreate)}>Thêm File</Button>
                </Row>
                <Search
                    size="large"
                    onChange={e =>
                        setSearchText(e.target.value)}
                    placeholder="Tìm kiếm hình ảnh"
                    value={searchText}
                    onPressEnter={onSearch}
                    onSearch={onSearch}
                />

                <Image.PreviewGroup
                    // preview={{
                    //     onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                    // }}
                >
                    <Table {...tableProps} columns={columns} dataSource={dataList} />
                </Image.PreviewGroup>

            </div>
        </>
    )
}
