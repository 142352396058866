import {
  Button,
  Col,
  Form,
  Modal,
  message,
  theme,
  Row,
  Select,
} from "antd";
import { useEffect, useState } from "react";
import { statusPageList } from "../../@mock/constants";
import { updateUserAPI } from "../../@lib/APIs/userManagerApi";

export const UpdateRoleForm = ({
  data,
  isOpen,
  handleOk,
  handleCancel,
  setData,
}) => {
  const [form] = Form.useForm();
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [isDisableForm, setIsDisableForm] = useState(false);
  const [statusPage, setStatusPage] = useState(statusPageList.create);
  const [messageApi, contextHolder] = message.useMessage();

  const onFinish = async (values) => {
    setIsDisableForm(true);
    try {
      let payload = {
        user_id: data.user_id,
        role: values.role,
      };
      if (statusPage === statusPageList.update) {
        let resUpdate = await updateUserAPI(payload, values);
        if (resUpdate) {
          messageApi.open({
            type: "success",
            content: "Cập nhật role thành công",
          });
        }
      }
    } catch (error) {
      console.log(error);
      messageApi.open({
        type: "error",
        content: "Có lỗi xảy ra, thử lại sau.",
      });
    }
    setIsDisableForm(false);
  };
  useEffect(() => {
    setIsDisableForm(false);
    form.resetFields();
    if (isOpen) {
      form.setFieldsValue({
        role: data?.role,
      });
    }
    if (data) {
      setStatusPage(statusPageList.update);
    } else {
      setStatusPage(statusPageList.create);
    }
  }, [isOpen]);

  return (
    <Modal
      title={data?.username ?? "Thêm mới"}
      open={isOpen}
      footer={false}
      onCancel={handleCancel}
      width={1000}
    >
      {contextHolder}
      <Form
        form={form}
        type="primary"
        layout="vertical"
        onFinish={onFinish}
        disabled={isDisableForm}
      >
        <Row gutter={[16, 16]}>
          <Col md={24}>
            <Form.Item
              name="role"
              label="Role"
              rules={[
                {
                  required: true,
                  message: "Role là bắt buộc!",
                },
              ]}
            >
              <Select
                defaultValue={"member"}
                options={[
                  { value: "member", label: "Member" },
                  { value: "vip", label: "Vip" },
                  { value: "admin", label: "Admin" },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item shouldUpdate>
          <div
            style={{
              display: "flex",
              gap: "15px",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="dafault"
              htmlType="button"
              onClick={handleCancel}
              style={{ border: "1px solid black" }}
            >
              Hủy
            </Button>
            {statusPage == statusPageList.update && (
              <Button type="primary" htmlType="submit">
                Cập nhật
              </Button>
            )}
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};
