export const routersList = {
    home: "/",
    matchManager: "/tran-dau",
    advManager: {
        motam: "/quang-cao/khandaia.mobi",
    },
    advCreate: (page)=>`/quang-cao/${page}/them-moi`,
    mediaManager: "/media",
    mediaCreate: "/media/them-moi",
    node: "/node",
    nodeRedis: "/redis_node",
    dealers: "/dai-ly",
    blogs: "/blogs",
    userManager: "/user-manager",
    domainsManager:"/domains",
    livePageManager:"/live-page"
}