import { Api } from "./APIconfig/Api";

// Node
export const getNodeListAPI = async () => {
    return await Api.post("/nodes");
}
export const getNodeAPI = async (id) => {
    return await Api.get("/node/"+id);
}
export const createNodeAPI = async (payload) => {
    return await Api.post("/node", payload);
}
export const deleteNodeAPI = async (id) => {
    return await Api.delete("/node/" + id, {
        data: {"deleted": true}
    });
}
export const updateNodeAPI = async (id, payload) => {
    return await Api.patch("/node/"+id, payload);
}

// Redis Node
export const getRedisNodeListAPI = async () => {
    return await Api.post("/redis_nodes");
}
export const getRedisNodeAPI = async (id) => {
    return await Api.get("/redis_node/"+id);
}
export const createRedisNodeAPI = async (payload) => {
    return await Api.post("/redis_node", payload);
}
export const deleteRedisNodeAPI = async (id) => {
    return await Api.delete("/redis_node/"+id, {
        data: {"deleted": true}
    });
}
export const updateRedisNodeAPI = async (id, payload) => {
    return await Api.patch("/redis_node/"+id, payload);
}

// Redis
export const getStatusRedis = async (id = "redis001") => {
    return await Api.get("/redis/" + id);
}
export const updateRedis = async (status, id = "redis001") => {
    return await Api.patch("/redis/" + id, {
        "deleted": status
      });
}