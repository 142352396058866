import Axios from 'axios'
import { HOST_API } from '../../@mock/constants';
function authRequestInterceptor(config) {
    config.headers = {
        "Accept": "application/json",
        "Authorization": "Basic YWRtaW46QW5oQDEyMzQ1Ng=="
    };
    return config;
}

const Api = Axios.create({
    baseURL: HOST_API,
});

Api.interceptors.request.use(authRequestInterceptor);


export const getHealthCheckAPI = async () => {
    return await Api.get("/health_check")
}

export const getCountChatConnection = async () => {
    return await Api.get("https://ws.gavang.tech/count");
    
}