import React from 'react'
import { Breadcrumb, Button, Input, Pagination, Popconfirm, Row, Switch, message, theme, Select } from 'antd';
import { Space, Table, Tag } from 'antd';
import { NavLink, useNavigate } from 'react-router-dom';
import { deleteMatchAPI, getListMatchAPI, resetStreamKeyAPI, updateDetailMatchAPI } from '../../@lib/APIs/manageAPI';
import { useState } from 'react';
import { routersList } from '../../@mock/routers';
import { FilterConstants } from '../../@mock/constants';


export const Manager = () => {
  const { token: { colorBgContainer }, } = theme.useToken();
  // const [dataListTop, setDataListTop] = React.useState([]);
  const [dataList, setDataList] = React.useState([]);
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const Search = Input.Search;
  const [messageApi, contextHolder] = message.useMessage();
  const [pagination, setPagination] = useState({ page: 1, limit: 10, total: 0 });
  const [filter, setFilter] = useState(FilterConstants.All)
  const [total, setTotal] = useState(0);

  const columns = [
    {
      title: 'Đội 1',
      dataIndex: 'team_1',
      key: 'team_1',
      // render: (text) => <a>{text}</a>,
    },
    {
      title: 'Đội 2',
      dataIndex: 'team_2',
      key: 'team_2',
    },
    {
      title: 'BLV',
      dataIndex: 'commentator',
      key: 'commentator',
      sorter: true,
    },
    {
      title: 'Tỉ số',
      key: 'score',
      dataIndex: 'score',
      render: (_, { score }) => (
        <>
          <Tag>
            {score.team_1_score + " - " + score.team_2_score}
          </Tag>
        </>
      ),
    },

    {
      title: 'Stream Key',
      key: 'stream_key',
      dataIndex: 'stream_key',
      render: (_, record) => (
        <a href={`${process.env.REACT_APP_HOST_CURRENT}/truc-tiep/${record.id}`} target='_blank' rel="noreferrer">
          {record.stream_key}
        </a>
      )
    },

    {
      title: 'Thời gian',
      dataIndex: 'schedule',
      key: 'schedule',
      sorter: (a, b) => (new Date(a.schedule + 'Z')) - (new Date(b.schedule + 'Z')),
      render: (text) => (new Date(text + 'Z')).toLocaleString(),
    },
    {
      title: 'Trực tiếp',
      key: 'is_live',
      dataIndex: 'is_live',
      sorter: (a, b) => a.is_live - b.is_live,
      render: (_, record) => (
        <>
          <Switch checked={record.is_live} onClick={() => handleChangeLive(record)} />

        </>
      ),
    },
    {
      title: 'Đầu trang',
      key: 'is_top',
      dataIndex: 'is_top',
      sorter: (a, b) => a.is_top - b.is_top,
      render: (_, record) => (
        <>
          <Switch checked={record.is_top} onClick={() => handleChangeTop(record)} />
        </>
      ),
    },
    {
      title: 'Siêu hot',
      key: 'prior',
      dataIndex: 'prior',
      sorter: (a, b) => a.is_top - b.is_top,
      render: (_, record) => (
        <>
          <Switch checked={record.prior == "True" ? true : false} onClick={() => handleChangePrior(record)} />
        </>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        return (
          <Space size="middle">
            <Popconfirm
              description="Reset Stream Key cho trận đấu này?"
              onConfirm={() => handleResetkey(record.stream_key)}
              okText="Reset"
              cancelText="Hủy"
            >
              <span href='#' style={{ cursor: "pointer", fontWeight: 500 }}>Reset</span>
            </Popconfirm>
            <NavLink to={routersList.matchManager + "/" + record.id}>
              Chi tiết </NavLink>
            <Popconfirm
              // title="Delete the task"
              description="Xóa trận đấu này?"
              onConfirm={() => removeMatch(record.id)}
              // onCancel={cancel}
              okText="Xóa"
              cancelText="Hủy"
            >
              <span href='#' style={{ color: "red", cursor: "pointer" }}>Xóa</span>
              {/* <Button danger>Xóa</Button> */}
            </Popconfirm>
          </Space>
        )
      },
    },
  ];

  const handleChangeLive = async (record) => {
    try {
      setLoading(true)
      let res = await updateDetailMatchAPI(record.id, { is_live: !record.is_live });
      if (res.status == 200) {
        setDataList(dataList.map(e => e.id === record.id ? { ...e, is_live: !record.is_live } : e));
      }
    } catch (error) {
      alert("đã xảy ra lỗi, thử lại sau.")
    }
    setLoading(false)
  }

  const handleChangePrior = async (record) => {
    try {
      setLoading(true)
      let prior = record.prior == "True" ? "False" : "True";
      let res = await updateDetailMatchAPI(record.id, { prior: prior });
      if (res.status == 200) {
        setDataList(dataList.map(e => e.id === record.id ? { ...e, prior: prior } : e));
      }
    } catch (error) {
      alert("đã xảy ra lỗi, thử lại sau.")
    }
    setLoading(false)
  }

  const handleChangeTop = async (record) => {
    try {
      setLoading(true)
      let res = await updateDetailMatchAPI(record.id, { is_top: !record.is_top });
      if (res.status == 200) {
        setDataList(dataList.map(e => e.id === record.id ? { ...e, is_top: !record.is_top } : e));
      }
    } catch (error) {
      alert("đã xảy ra lỗi, thử lại sau.")
    }
    setLoading(false)
  }

  const removeMatch = async (matchId) => {
    try {
      setLoading(true)
      let resDel = await deleteMatchAPI(matchId);
      if (resDel.status == 200) {
        setDataList(dataList.filter(e => e.id != matchId));
      }
    } catch (error) {
      alert("đã xảy ra lỗi, thử lại sau.")
    }
    setLoading(false)
  }

  const handleResetkey = async (streamKey) => {
    try {
      let resResetKey = await resetStreamKeyAPI(streamKey);
      if (resResetKey.status == 200) {
        messageApi.open({
          type: 'success',
          content: 'Reset stream key thành công',
        });
        getList();
      }
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: 'Có lỗi xảy ra, thử lại sau',
      });
      console.log(error);
    }
  }

  const getList = async () => {
    try {
      setLoading(true)
      let res = await getListMatchAPI(pagination.limit, pagination.page, searchText, filter);
      if (res.status == 200) {
        setDataList(res.data.data);
        setTotal(res.data.total)
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false)
  }

  const handleChangeFilter = async (value) => {
    setFilter(value)
  };

  React.useEffect(() => {
    getList();
  }, [pagination, filter]);

  const tableProps = {
    loading
  };

  return (
    <>
      {contextHolder}
      <Breadcrumb
        style={{
          margin: '16px 0',
        }}
        items={[{ title: "Quản lý" }, { title: "Danh sách trận đấu" },]}
      >
      </Breadcrumb>
      <div
        style={{
          padding: 24,
          minHeight: 360,
          background: colorBgContainer,
        }}
      >
        <Row style={{ justifyContent: 'flex-end', padding: 10, alignItems: "center", gap: 10 }}>
          <div style={{ justifyContent: 'flex-end', alignItems: "center", gap:5 }}>
            <span>Filter:</span>
            <Select
              la
              defaultValue={FilterConstants.All}
              style={{ width: 120 }}
              onChange={handleChangeFilter}
              options={[
                { value: FilterConstants.All, label: 'Tất cả' },
                { value: FilterConstants.BLV, label: 'BLV' },
                { value: FilterConstants.Hot, label: 'Siêu Hot' },
                { value: FilterConstants.Live, label: 'Trực tiếp' },
                { value: FilterConstants.Top, label: 'Đầu trang' },
              ]}
            />

          </div>
          <Button type="primary" onClick={() => { navigate(routersList.matchManager + '/them-moi') }}>Thêm trận đâu</Button>

        </Row>
        <Search
          size="large"
          onChange={e =>
            setSearchText(e.target.value)}
          placeholder="Search Records"
          value={searchText}
          onPressEnter={getList}
          onSearch={getList}
        />
        <Table {...tableProps} columns={columns} dataSource={dataList} pagination={false} />
        <Pagination pageSize={pagination.limit} current={pagination.page} total={total} onChange={(page, pageSize) => {
          setPagination({ limit: pageSize, page: page })
        }}
          style={{ marginTop: 5 }}
        />
      </div>
    </>
  )
}
