import { FilterConstants } from "../../@mock/constants";
import { Api } from "./APIconfig/Api";

export const getListMatchAPI = async (limit = 1000, page = 1, keyWord = "", filter) => {
    let payload = {
        order_asc: "schedule", limit, page,
        queries: []
    }
    if (keyWord) {
        payload.queries = [
            { field: "team_1", type: "search", value: keyWord },
            { field: "team_2", type: "search", value: keyWord },
        ];
        payload.query_or = true;
    }

    switch (filter) {
        case FilterConstants.All:
            break;
        case FilterConstants.BLV:
            payload.queries.push({ field: "commentator", type: "not_equal", value: null })
            break;
        case FilterConstants.Hot:
            payload.queries.push({ field: "prior", type: "equal", value: "True" })
            break;
        case FilterConstants.Live:
            payload.queries.push({ field: "is_live", type: "equal", value: true })
            break;
        case FilterConstants.Top:
            payload.queries.push({ field: "is_top", type: "equal", value: true })
            break;
        default:
            break;
    }
    return await Api.post("/schedules/graph", payload);
}

export const getDetailMatchAPI = async (matchId) => {
    return await Api.post("/schedules/graph", {
        queries: [
            { field: "id", type: "equal", value: matchId },
        ],
    })
}

export const createMatchAPI = async (payload) => {
    return await Api.post("/schedules", payload);
}
export const updateDetailMatchAPI = async (matchId, payload) => {
    return await Api.patch("/schedule/" + matchId, payload);
}
export const deleteMatchAPI = async (matchId) => {
    return await Api.delete("/schedule/" + matchId);
}

export const resetStreamKeyAPI = async (key) => {
    return await Api.patch("/streams/renew", { stream_key: key });
}