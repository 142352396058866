import React, { useEffect } from "react";
import {
  Breadcrumb,
  Button,
  Input,
  Popconfirm,
  Row,
  Select,
  Switch,
  message,
  theme,
} from "antd";
import { Space, Table, Tag } from "antd";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { getListUserAPI } from "../../@lib/APIs/userManagerApi";
import { UpdateRoleForm } from "./UpdateRoleForm";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  StepBackwardOutlined,
} from "@ant-design/icons";

export const UserManager = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [dataListTop, setDataListTop] = React.useState([]);
  const [dataList, setDataList] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const Search = Input.Search;
  const [messageApi, contextHolder] = message.useMessage();
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [queryParameters, setSearchParams] = useSearchParams();
  const [pagination, setPagination] = useState({
    page_size: 25,
    page_index: 1,
    search:""
  });

  const handleClose = () => {
    setIsOpenForm(false);
    setCurrentData(null);
    getList();
  };
  const handleOpen = () => setIsOpenForm(true);
  const columns = [
    // {
    //   title: "STT",
    //   dataIndex: "index",
    //   key: "index",
    //   sortable: true,
    //   render: (text, record, index) => index + 1,
    // },
    {
      title: "User name",
      dataIndex: "username",
      key: "username",
      defaultSortOrder: "ascend",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Số messages",
      dataIndex: "num_message",
      key: "num_message",
      sorter: (a, b) => parseInt(a.num_message) - parseInt(b.num_message),
      render: (_, { num_message }) => <Tag>{num_message}</Tag>,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <Space size="middle">
            <NavLink
              onClick={() => {
                setCurrentData(record);
                handleOpen();
              }}
            >Edit</NavLink>
          </Space>
        );
      },
    },
  ];

  const handleNextPage = async () => {
    try {
      setPagination({...pagination, page_index: pagination.page_index+1})
    } catch (error) {
      console.log(error);
    }
  };
  const handlePrevPage = async () => {
    try {
      setPagination({...pagination, page_index: pagination.page_index-1})
    } catch (error) {
      console.log(error);
    }
  };
  const handleFirstPage = async () => {
    try {
      setPagination({...pagination, page_index: 1})
    } catch (error) {
      console.log(error);
    }
  };

  const onSearch = () => {
    setPagination({...pagination, search: searchText, page_index: 1})
  };

  const getList = async () => {
    // debugger
    setLoading(true);
    try {
      let query =  `?offset=${pagination.page_index}&limit=${pagination.page_size}`;
      if(pagination.search){
        query += `&username=${pagination.search}`;
      }
      setSearchParams(query)
      let res = await getListUserAPI(query);
      if (res.status == 200) {
        setDataList(res.data);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  React.useEffect(() => {
    let page_index = queryParameters.get("offset") ?? pagination.page_index;
    let page_size = queryParameters.get("limit") ?? pagination.page_size;
    let search = queryParameters.get("username") ?? pagination.search;
    setPagination({
      page_index,
      page_size,
      search
    });
    setSearchText(search)
  }, []);

  React.useEffect(() => {
    getList();
  }, [pagination]);
  React.useEffect(() => {
console.log(dataList.length);
  }, [dataList]);

  const tableProps = {
    loading,
  };


  return (
    <>
      {contextHolder}
      <Breadcrumb
        style={{
          margin: "16px 0",
        }}
        items={[{ title: "Người dùng" }, { title: "Danh sách người dùng" }]}
      ></Breadcrumb>
      <div
        style={{
          padding: 24,
          minHeight: 360,
          background: colorBgContainer,
        }}
      >
        <Search
          size="large"
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Search Records"
          value={searchText}
          onPressEnter={onSearch}
          onSearch={onSearch}
        />
        <Table
          {...tableProps}
          pagination={false}
          columns={columns}
          dataSource={dataList}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 10,
            gap: 5,
          }}
        >
          <Button style={{}} disabled={pagination.page_index <= 1} onClick={handleFirstPage}>
            <StepBackwardOutlined />
          </Button>
          <Button style={{}} disabled={pagination.page_index <= 1} onClick={handlePrevPage}> 
            <ArrowLeftOutlined />
          </Button>
          <Button style={{}} onClick={handleNextPage} disabled = {dataList.length < pagination.page_size}>
            <ArrowRightOutlined />
          </Button>
        </div>
      </div>
      <UpdateRoleForm
        data={currentData}
        setData={setCurrentData}
        isOpen={isOpenForm}
        handleCancel={handleClose}
      />
    </>
  );
};
